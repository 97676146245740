var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-start" },
    [
      _c(
        _vm.field.component,
        {
          tag: "component",
          class: { "readonly-input": !_vm.thisFieldIsBeingEdited },
          style: _vm.inputStyleObj,
          attrs: {
            required: _vm.field.required,
            readonly: !_vm.thisFieldIsBeingEdited,
            label: _vm.field.label,
            type: _vm.field.type,
            step: _vm.step,
            items: _vm.itemsComputed,
            success: _vm.success,
            error: _vm.error,
            messages: _vm.messages,
          },
          on: {
            change: function ($event) {
              return _vm.submitSelectChange()
            },
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                ) {
                  return null
                }
                $event.preventDefault()
                return _vm.submitChange("tab")
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.submitChange("enter")
              },
            ],
            dblclick: _vm.toggleThisFieldBeingEdited,
          },
          model: {
            value: _vm.vModel,
            callback: function ($$v) {
              _vm.vModel = $$v
            },
            expression: "vModel",
          },
        },
        [
          _c(
            "v-icon",
            {
              style: _vm.submitIconStyleObj,
              attrs: { slot: "append" },
              on: {
                click: function ($event) {
                  return _vm.submitChange("click")
                },
              },
              slot: "append",
            },
            [_vm._v(" mdi-check-circle ")]
          ),
          _c(
            "v-icon",
            {
              staticClass: "edit-icon",
              class: _vm.editIconClassObj,
              attrs: {
                slot: "append",
                id: _vm.field.objectPath + "-edit-icon",
              },
              on: {
                mouseover: function ($event) {
                  return _vm.mouseoverOrOutIcon("over")
                },
                mouseout: function ($event) {
                  return _vm.mouseoverOrOutIcon("out")
                },
                click: _vm.toggleThisFieldBeingEdited,
              },
              slot: "append",
            },
            [_vm._v(" " + _vm._s(_vm.editIconName) + " ")]
          ),
          _c(
            "v-icon",
            {
              attrs: { slot: "append" },
              on: {
                click: function ($event) {
                  return _vm.copyToClipboard(_vm.vModel)
                },
              },
              slot: "append",
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.copied ? "mdi-check" : "mdi-content-copy") +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }