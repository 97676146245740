export function createInverterFields(inverter) {
  return {
    name: {
      objectPath: 'description',
      inverterId: inverter.id,
      component: 'v-text-field',
      required: true,
      label: 'Description (Nickname, inverter #, etc.)',
    },
    inverterMonitoringId: {
      objectPath: 'inverterMonitoringId',
      inverterId: inverter.id,
      component: 'v-text-field',
      required: true,
      label: 'Inverter Monitoring Id'
    },
    aCSize: {
      objectPath: 'aCSize',
      inverterId: inverter.id,
      component: 'v-text-field',
      required: true,
      type: 'number',
      decimalPlaces: 2,
      label: 'AC Size in kW',
    },
    dCSize: {
      objectPath: 'dCSize',
      inverterId: inverter.id,
      component: 'v-text-field',
      required: true,
      type: 'number',
      decimalPlaces: 2,
      label: 'DC Size in kW',
    },
    azimuth: {
      objectPath: 'azimuth',
      inverterId: inverter.id,
      component: 'v-text-field',
      required: true,
      type: 'number',
      decimalPlaces: 0,
      label: 'Azimuth',
    },
    tilt: {
      objectPath: 'tilt',
      inverterId: inverter.id,
      component: 'v-text-field',
      required: true,
      type: 'number',
      decimalPlaces: 0,
      label: 'Tilt',
    },
    numberOfModules: {
      objectPath: 'numberOfModules',
      inverterId: inverter.id,
      component: 'v-text-field',
      required: true,
      type: 'number',
      decimalPlaces: 0,
      label: 'Number of Modules',
    }
  };
};
