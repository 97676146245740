var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "button",
            {
              staticClass: "v-btn white",
              attrs: { id: "x_btn" },
              on: { click: _vm.closeNewContact },
            },
            [_vm._v(" ✖ ")]
          ),
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { staticClass: "white" },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "mt-4",
                      attrs: { "align-center": "", "justify-center": "" },
                    },
                    [_c("h4", [_vm._v("Add New Contact")])]
                  ),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { id: "account_form", "lazy-validation": "" },
                    },
                    [
                      _c(
                        "v-alert",
                        { attrs: { value: _vm.showError, type: "error" } },
                        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                      ),
                      _c("v-text-field", {
                        attrs: {
                          label: "First Name",
                          required: "",
                          rules: [
                            function (v) {
                              return !!v || "Name is required"
                            },
                          ],
                        },
                        model: {
                          value: _vm.name,
                          callback: function ($$v) {
                            _vm.name = $$v
                          },
                          expression: "name",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Last Name",
                          required: "",
                          rules: [
                            function (v) {
                              return !!v || "Last name is required"
                            },
                          ],
                        },
                        model: {
                          value: _vm.lastName,
                          callback: function ($$v) {
                            _vm.lastName = $$v
                          },
                          expression: "lastName",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: { label: "Email" },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: { label: "Phone" },
                        model: {
                          value: _vm.phone,
                          callback: function ($$v) {
                            _vm.phone = $$v
                          },
                          expression: "phone",
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "\n                    mb-4\n                    font-weight-bold\n                    text-xs\n                    btn-success\n                    bg-gradient-success",
                          attrs: { ripple: false, elevation: 0, block: "" },
                          on: { click: _vm.createContact },
                        },
                        [_vm._v(" Create Contact ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }