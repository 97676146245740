var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticStyle: { "margin-bottom": "20px" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { justify: "start" } },
            _vm._l(["name", "inverterMonitoringId"], function (field) {
              return _c(
                "v-col",
                {
                  key: _vm.inverterId + "-" + _vm.fields[field].objectPath,
                  attrs: { sm: "6" },
                },
                [
                  _c("site-detail-input", {
                    attrs: {
                      field: _vm.fields[field],
                      inverterId: _vm.inverterId,
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-row",
            { attrs: { justify: "start" } },
            _vm._l(["dCSize", "aCSize"], function (field) {
              return _c(
                "v-col",
                {
                  key: _vm.inverterId + "-" + _vm.fields[field].objectPath,
                  attrs: { sm: "6" },
                },
                [
                  _c("site-detail-input", {
                    attrs: {
                      field: _vm.fields[field],
                      inverterId: _vm.inverterId,
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-row",
            { attrs: { justify: "start" } },
            _vm._l(["azimuth", "tilt"], function (field) {
              return _c(
                "v-col",
                {
                  key: _vm.inverterId + "-" + _vm.fields[field].objectPath,
                  attrs: { sm: "6" },
                },
                [
                  _c("site-detail-input", {
                    attrs: {
                      field: _vm.fields[field],
                      inverterId: _vm.inverterId,
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-row",
            { attrs: { justify: "start" } },
            [
              _c(
                "v-col",
                { attrs: { sm: "6" } },
                [
                  _vm._v("Paused "),
                  _c("v-checkbox", {
                    model: {
                      value: _vm.isPaused,
                      callback: function ($$v) {
                        _vm.isPaused = $$v
                      },
                      expression: "isPaused",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "error" },
              on: {
                click: function ($event) {
                  return _vm.toggleShowConfirmModal(true)
                },
              },
            },
            [_vm._v(" Remove ")]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "290" },
              model: {
                value: _vm.showConfirmModal,
                callback: function ($$v) {
                  _vm.showConfirmModal = $$v
                },
                expression: "showConfirmModal",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title"),
                  _c("v-card-text", [
                    _vm._v("Do you really want to remove this inverter?"),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "black", outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleShowConfirmModal(false)
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "error" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteInverter()
                            },
                          },
                        },
                        [_vm._v(" Remove ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }