import Service from '@/services/Service.js';

export default {
	methods: {
		submitSelectChange() {
      if (this.field.component === 'v-select') {
        this.submitChange('select');
      }
    },
    getKeyForApi(objectPath) {
      const nestedKeys = objectPath.split('.');
      const keyForApi = nestedKeys[nestedKeys.length - 1];

      return keyForApi;
    },
    async submitChange(eventType, suppressMessage, overrideObjectPath, overrideValue, suppressResetEditMode) {
      if (this.inputIsValid) {
        if (this.thisFieldIsBeingEdited) {
          let keyForApi;
          let params;
          let value;

          if (!overrideObjectPath) {
            keyForApi = this.getKeyForApi(this.field.objectPath);
            value = this.vModel;
          } else {
            keyForApi = this.getKeyForApi(overrideObjectPath);
            value = overrideValue;
          }

          params = {
            [keyForApi]: value,
          };

          if (!this.field.inverterId) {
            try {
              const response = await Service.updateSite(this.currentSite.id, params);

              this.setCurrentSite({
                value: response.data
              });

              this.setMessage('Field updated', true, suppressMessage);

              this.editNextInputOnTab(eventType);
            } catch (e) {
              this.setMessage('There was a problem updating the site', false, suppressMessage);
            }
          } else {
            try {
              const response = await Service.updateInverter(this.currentSite.id, this.inverterId, params);

              response.data.id = response.data.id.toString();

              this.setCurrentSiteInverters(
                {
                operation: 'update',
                inverterId: this.field.inverterId,
                updatedInverter: response.data
              });

              this.setMessage('Field updated', true, suppressMessage);

              this.editNextInputOnTab(eventType);
            } catch (e) {
              this.setMessage('There was a problem updating the inverter', false, suppressMessage);
            }
          }

          if (!suppressResetEditMode) {
            this.resetEditMode();
          }

          setTimeout(() => {
            this.messages = '';
            this.success = false;
          }, 2000);
        }
      }
    },
    setMessage(messages, success, suppressMessage) {
      if (!suppressMessage) {
        this.messages = messages;
        this.success = success;
      }
    },
	},
};
