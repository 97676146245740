<template>
    <v-dialog v-model="show" width="500">
      <v-card>
       <button class="v-btn white" id="x_btn" @click="closeNewContact">
        &#10006;
       </button>
       <v-layout align-center justify-center>
           <v-flex class="white">
                <v-layout align-center justify-center class="mt-4">
                   <h4>Add New Contact</h4>
                </v-layout>
                <v-form
                ref="form"
                id="account_form"   
                lazy-validation
                >
                    <v-alert
                        :value="showError"
                        type="error"
                    >
                        {{ errorMessage }}
                    </v-alert>

                    <v-text-field
                        v-model="name"
                        label="First Name"
                        required
                        :rules="[(v) => !!v || 'Name is required']"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="lastName"
                        label="Last Name"
                        required
                        :rules="[(v) => !!v || 'Last name is required']"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="email"
                        label="Email"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="phone"
                        label="Phone"
                    >
                    </v-text-field>
                    <v-btn
                        :ripple="false"
                        :elevation="0"
                        class="
                        mb-4
                        font-weight-bold
                        text-xs
                        btn-success
                        bg-gradient-success"
                        block
                        @click="createContact"
                    >
                        Create Contact
                    </v-btn>

                </v-form>
           </v-flex>
       </v-layout>
      </v-card>
    </v-dialog>
</template>
<script>
import Service from '@/services/Service.js';

import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";

Vue.use(VueToast)
export default {
  components: {

  },
  props: {
    siteID: String,
    value: Boolean,
    dataItem: Object,
    field: String
  },
  data() {
    return {
      showError: false,
      show1: false,
      gridData: [],
      defaultItem: {
        name: "Select customer ...",
        id: 0,
      },
      errorMessage: "",
      password: '',
      name: '',
      lastName: '',
      email: '',
      phone: '',
      errorMessage: "",
      disabled: true,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    }
  },
  computed: {
    show: {
        get () {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
    user: function () {
      return this.$store.getters.currentUser;
    },
  },
  created: function () {
    //get contacts?
    // this.getCustomers();
    // this.getData();
    },
  methods: {
    closeNewContact() {
      this.show = false;
    },
    // getData: function () {
    //   Service.getUserTypes()
    //     .then((response) => {
    //       // console.log("getData call... set gridData");
    //       this.usertypes = response.data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // change this to contact form??
    createContact () {
      let params = {
          name: this.name,
          last_name: this.lastName,
          email: this.email,
          phone: this.phone,
          customer_id: this.user.customer.id
      }
      const site_id = parseInt(this.siteID)
      Service.createContactSiteRelation(site_id, params)
        .then((response) => {
          // message
          console.log(response.data)
          this.$emit('my-message', "reload")
        })
        .catch((error) => {
          console.log("Error with contact registration:", error.response);
        });
      },

    show_submit() {
      let filled = this.email != ""
      if (this.valid && filled) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    validate() {
        if (this.$refs.form.validate()) {
            this.snackbar = true
        }
    },
    register() {
      this.errorMessage = "";
      let data = {
        user: {
          email: this.email,
          name: `${this.firstName} ${this.lastName}`,
          password: this.password
        }
      };
    }
  }
}
</script>
<style>
#account_form {
  margin-left: 6%;
  margin-right: 0%;
}
#x_btn {
  float: right;
  width: 32px;
  height: 32px;
}
</style>