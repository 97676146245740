export default {
	computed: {
		vModel: {
      get() {
        return this.getVModel();
      },
      set(value) {
        this.setValueBeingEdited({
          value
        });
      },
    },
    valueInCurrentSiteOrInverter() {
      let value;

      if (!this.field.inverterId) {
        const nested = this.field.objectPath.split('.');

        if (nested.length === 1) {
          value = this.currentSite[nested[0]];
        } else if (nested.length === 2) {
          value = this.currentSite[nested[0]][nested[1]];
        }
      } else {
        const inverter = this.currentSiteInverters.find(inverter => inverter.id === this.field.inverterId);

        value = inverter[this.field.objectPath];
      }

      return value;
    },
    thisFieldIsBeingEdited() {
      let beingEdited;

      if (this.inverterIdBeingEdited) {
        beingEdited = this.inverterId === this.inverterIdBeingEdited && this.field.objectPath === this.objectPathBeingEdited;
      } else {
        beingEdited = this.field.objectPath === this.objectPathBeingEdited;
      }

      return beingEdited;
    },
        inputIsValid() {
      let valid = true;

      // validate number fields for decimal places
      if (this.thisFieldIsBeingEdited && this.field.type === 'number' && this.vModel) {
        const inputAsString = this.vModel.toString();

        const numberComponents = inputAsString.split('.');

        if (!this.field.decimalPlaces) {
          if (numberComponents.length > 1) {
            valid = false;
          }
        } else {
          let numberOfDecimalPlaces;

          if (numberComponents.length > 1) {
            numberOfDecimalPlaces = numberComponents[1].length;
          } else {
            numberOfDecimalPlaces = 0;
          }

          if (numberOfDecimalPlaces > this.field.decimalPlaces) {
            valid = false;
          }
        }
      }

      return valid;
    },
	},
	watch: {
    vModel(val) {
      if (!this.inputIsValid) {
        this.error = true;

        let messages;

        if (this.field.decimalPlaces) {
          messages = `Enter no more than ${this.field.decimalPlaces} decimal places.`;
        } else {
          messages = 'Enter an integer.'
        }

        this.messages = messages;
      } else {
        this.error = false;
        this.messages = '';
      }
    },
    thisFieldIsBeingEdited(val) {
      if (!val) {
        this.resetEditMode();

        this.vModel = this.getVModel();
      }
    }
  },
  created() {
    this.setObjectPathBeingEdited({
      objectPath: null
    });
  },
  methods: {  	
    getVModel() {
      let ret;

      if (!this.field.inverterId) {
        if (!this.thisFieldIsBeingEdited) {
          ret = this.valueInCurrentSiteOrInverter;
        } else {
          ret = this.valueBeingEdited;
        }
      } else {
        if (!this.thisFieldIsBeingEdited) {
          const inverter = this.currentSiteInverters.find(inverter => inverter.id === this.field.inverterId);

          ret = inverter[this.field.objectPath];
        } else {
          ret = this.valueBeingEdited;
        }
      }

      if (this.field.type === 'number' && !this.thisFieldIsBeingEdited && ret !== null) {
        ret = Number(ret).toFixed(this.field.decimalPlaces);
      }

      return ret;
    },
    toggleThisFieldBeingEdited() {
      if (!this.thisFieldIsBeingEdited) {
        this.setValueBeingEdited({
          value: this.valueInCurrentSiteOrInverter,
        });

        this.setObjectPathBeingEdited({
          value: this.field.objectPath,
        });

        this.setInverterIdBeingEdited({
          value: this.inverterId,
        });
      } else {
        this.setValueBeingEdited({
          value: null,
        });

        this.resetEditMode();
      }
    },
    resetEditMode() {
      this.setValueBeingEdited({
        value: null,
      });

      this.setObjectPathBeingEdited({
        value: null,
      });
    },
  },
};
