<template>
  <div>
    <v-container fluid id="metric_stuff" class="px-6 py-6">
      <div class="d-flex-row">
        <h3 id="power_title" class="mt-6">Power and Energy</h3>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default btn-outline-default"
          color="transparent"
          id="monitoring_link_btn"
          @click="openWindow(nativeLink)"
        >
          <img :src="require('@/assets/img/' + this.system + '.png')" />
        </v-btn>
        <v-btn
          v-if="plans"
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          id="site_plans_btn"
          @click="openWindow(plans)"
        >
          Site Plans
        </v-btn>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          id="inverter_btn"
          @click="openWindow(`/sites/` + site_id + `/inverters_list`)"
        >
          Inverters
        </v-btn>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          id="inverters_btn"
          @click="reloadMonthlyData(site_id)"
        >
          Reload Monthly Data
        </v-btn>
      </div>
      <!-- Chart -->
      <v-row class="mt-1">
        <v-col lg="7">
          <v-tabs background-color="transparent" grow v-model="NewTab">
            <v-tab href="#metrics_month" @change="selectMonthlyTab()">
              Month
            </v-tab>
            <v-tab href="#metrics_year" @change="selectYearlyTab()">
              Year
            </v-tab>
            <v-tab href="#metrics_all_time" @change="selectAllTimeTab()">
              All Time
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="NewTab">
            <!-- month -->
            <v-tab-item value="metrics_month">
              <v-card class="card-shadow border-radius-xl h-100">
                <div class="px-4 py-4">
                  <apexchart
                    height="400"
                    type="bar"
                    :options="options"
                    :series="series"
                  ></apexchart>
                </div>
              </v-card>
            </v-tab-item>
            <!-- year -->
            <v-tab-item value="metrics_year">
              <v-card class="card-shadow border-radius-xl h-100">
                <div class="px-4 py-4">
                  <apexchart
                    height="400"
                    type="line"
                    :options="yearlyOptions"
                    :series="series"
                  ></apexchart>
                </div>
              </v-card>
            </v-tab-item>
            <!-- all time -->
            <v-tab-item value="metrics_all_time">
              <v-card class="card-shadow border-radius-xl h-100">
                <div class="px-4 py-4">
                  <apexchart
                    height="400"
                    type="bar"
                    :options="allTimeOptions"
                    :series="series"
                  ></apexchart>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col cols="8" lg="5">
          <v-card
            class="card-shadow border-radius-xl mt-12 mb-8"
            v-if="monthDisplay"
          >
            <div class="pb-0 px-4 pt-4">
              <div class="d-flex align-items-center">
                <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                  Customize View
                </h6>
              </div>
            </div>
            <div>
              <v-row>
                <v-col cols="6">
                  <div class="ml-6 mt-4">
                    <v-select
                      :items="months"
                      label="Select Month"
                      v-model="selectedMonth"
                      required
                    ></v-select>
                    <v-select
                      :items="monthlyYears"
                      label="Select Year"
                      v-model="selectedMonthlyYear"
                      required
                    ></v-select>
                    <v-btn
                      text
                      elevation="0"
                      style="font-size: 10px"
                      id="left_arrow"
                      @click="previousMonth()"
                    >
                      <v-icon left class="material-icons">chevron_left</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      elevation="0"
                      style="font-size: 10px"
                      id="right_arrow"
                      @click="nextMonth()"
                    >
                      <v-icon right class="material-icons"
                        >chevron_right</v-icon
                      >
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                      "
                      id="customize_view_btn"
                      @click="loadMonthlyMetrics()"
                    >
                      Apply</v-btn
                    >
                  </div>
                </v-col>
                <v-col cols="6">
                  <div
                    class="
                      bg-gradient-primary
                      shadow-primary
                      border-radius-md
                      mt-n12
                      ml-2
                      mr-6
                      mb-4
                    "
                  >
                    <v-checkbox
                      color="white"
                      class="ml-3 pt-3"
                      @change="kwhSelectSwitch"
                      v-model="kwhSelect"
                      ><template v-slot:label>
                        <div>
                          <p class="text-white text-sm mb-0 font-weight-bolder">
                            kWh
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                    <v-checkbox
                      color="white"
                      class="ml-3"
                      @change="estimateSelectSwitch"
                      v-model="estimateSelect"
                      ><template v-slot:label
                        ><div>
                          <p
                            class="
                              text-white text-sm
                              mb-0
                              text-capitalize
                              font-weight-bolder
                            "
                          >
                            Estimate
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                    <v-checkbox
                      color="white"
                      class="ml-3"
                      @change="projectedSelectSwitch"
                      v-model="projectedSelect"
                      ><template v-slot:label
                        ><div>
                          <p
                            class="
                              text-white text-sm
                              mb-0
                              text-capitalize
                              font-weight-bolder
                            "
                          >
                            Projected
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                    <!-- <v-checkbox
                      color="white"
                      class="ml-3"
                      @change="kwhKwpSelectSwitch"
                      v-model="kwhKwpSelect"
                      ><template v-slot:label
                        ><div>
                          <p
                            class="
                              text-white text-sm
                              mb-0
                              text-capitalize
                              font-weight-bolder
                            "
                          >
                            Performance
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                    <v-checkbox
                      color="white"
                      class="ml-3"
                      @change="performanceSelectSwitch"
                      v-model="performanceSelect"
                      ><template v-slot:label>
                        <div>
                          <p
                            class="
                              text-white text-sm
                              mb-0
                              text-capitalize
                              font-weight-bolder
                            "
                          >
                            Normalized performance
                          </p>
                        </div>
                      </template>
                    </v-checkbox> -->
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <v-card
            class="card-shadow border-radius-xl mt-12 mb-8"
            v-else-if="yearDisplay"
          >
            <div class="pb-0 px-4 pt-4">
              <div class="d-flex align-items-center">
                <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                  Customize View
                </h6>
              </div>
            </div>
            <div>
              <v-row>
                <v-col cols="6">
                  <div class="ml-6 mt-4">
                    <v-select
                      :disabled="true"
                      :items="months"
                      label="Select Month"
                      v-model="selectedMonth"
                      required
                    ></v-select>
                    <v-select
                      :items="yearlyYears"
                      label="Select Year"
                      v-model="selectedYearlyYear"
                      required
                    ></v-select>
                    <v-btn
                      text
                      elevation="0"
                      style="font-size: 10px"
                      id="left_arrow"
                      @click="previousYear()"
                    >
                      <v-icon left class="material-icons">chevron_left</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      elevation="0"
                      style="font-size: 10px"
                      id="right_arrow"
                      @click="nextYear()"
                    >
                      <v-icon right class="material-icons"
                        >chevron_right</v-icon
                      >
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                      "
                      id="customize_view_btn"
                      @click="loadYearlyMetrics()"
                    >
                      Apply</v-btn
                    >
                  </div>
                </v-col>
                <v-col cols="6">
                  <div
                    class="
                      bg-gradient-primary
                      shadow-primary
                      border-radius-md
                      mt-n12
                      ml-2
                      mr-6
                      mb-4
                    "
                  >
                    <v-checkbox
                      class="ml-3 pt-3"
                      @change="kwhSelectSwitch"
                      v-model="kwhSelect"
                      ><template v-slot:label>
                        <div>
                          <p class="text-white text-sm mb-0 font-weight-bolder">
                            kWh
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                    <v-checkbox
                      class="ml-3"
                      @change="estimateSelectSwitch"
                      v-model="estimateSelect"
                      ><template v-slot:label
                        ><div>
                          <p
                            class="
                              text-white text-sm
                              mb-0
                              text-capitalize
                              font-weight-bolder
                            "
                          >
                            Estimate
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                    <v-checkbox
                      class="ml-3"
                      @change="projectedSelectSwitch"
                      v-model="projectedSelect"
                      ><template v-slot:label
                        ><div>
                          <p
                            class="
                              text-white text-sm
                              mb-0
                              text-capitalize
                              font-weight-bolder
                            "
                          >
                            Projected
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                    <!-- <v-checkbox
                      class="ml-3"
                      @change="kwhKwpSelectSwitch"
                      v-model="kwhKwpSelect"
                      ><template v-slot:label
                        ><div>
                          <p
                            class="
                              text-white text-sm
                              mb-0
                              text-capitalize
                              font-weight-bolder
                            "
                          >
                            Performance
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                    <v-checkbox
                      class="ml-3"
                      @change="performanceSelectSwitch"
                      v-model="performanceSelect"
                      ><template v-slot:label>
                        <div>
                          <p
                            class="
                              text-white text-sm
                              mb-0
                              text-capitalize
                              font-weight-bolder
                            "
                          >
                            Normalized performance
                          </p>
                        </div>
                      </template>
                    </v-checkbox> -->
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <v-card class="card-shadow border-radius-xl mt-12 mb-8" v-else>
            <div class="pb-0 px-4 pt-4">
              <div class="d-flex align-items-center">
                <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                  Customize View
                </h6>
              </div>
            </div>
            <div>
              <v-row>
                <v-col cols="6">
                  <div class="ml-6 mt-4">
                    <v-select
                      :disabled="true"
                      :items="months"
                      label="Select Month"
                      v-model="selectedMonth"
                      required
                    ></v-select>
                    <v-select
                      :disabled="true"
                      :items="monthlyYears"
                      label="Select Year"
                      v-model="selectedMonthlyYear"
                      required
                    ></v-select>
                    <v-btn
                      text
                      elevation="0"
                      style="font-size: 10px"
                      id="left_arrow"
                    >
                      <v-icon left class="material-icons">chevron_left</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      elevation="0"
                      style="font-size: 10px"
                      id="right_arrow"
                    >
                      <v-icon right class="material-icons"
                        >chevron_right</v-icon
                      >
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                      "
                      id="customize_view_btn"
                    >
                      Apply</v-btn
                    >
                  </div>
                </v-col>
                <v-col cols="6">
                  <div
                    class="
                      bg-gradient-primary
                      shadow-primary
                      border-radius-md
                      mt-n12
                      ml-2
                      mr-6
                      mb-4
                    "
                  >
                    <v-checkbox
                      class="ml-3 pt-3"
                      @change="kwhSelectSwitch"
                      v-model="kwhSelect"
                      ><template v-slot:label>
                        <div>
                          <p class="text-white text-sm mb-0 font-weight-bolder">
                            kWh
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                    <v-checkbox
                      class="ml-3"
                      @change="estimateSelectSwitch"
                      v-model="estimateSelect"
                      ><template v-slot:label
                        ><div>
                          <p
                            class="
                              text-white text-sm
                              mb-0
                              text-capitalize
                              font-weight-bolder
                            "
                          >
                            Estimate
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                    <v-checkbox
                      class="ml-3"
                      @change="projectedSelectSwitch"
                      v-model="projectedSelect"
                      ><template v-slot:label
                        ><div>
                          <p
                            class="
                              text-white text-sm
                              mb-0
                              text-capitalize
                              font-weight-bolder
                            "
                          >
                            Projected
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                    <!-- <v-checkbox
                      class="ml-3"
                      @change="kwhKwpSelectSwitch"
                      v-model="kwhKwpSelect"
                      ><template v-slot:label
                        ><div>
                          <p
                            class="
                              text-white text-sm
                              mb-0
                              text-capitalize
                              font-weight-bolder
                            "
                          >
                            Performance
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                    <v-checkbox
                      class="ml-3"
                      @change="performanceSelectSwitch"
                      v-model="performanceSelect"
                      ><template v-slot:label>
                        <div>
                          <p
                            class="
                              text-white text-sm
                              mb-0
                              text-capitalize
                              font-weight-bolder
                            "
                          >
                            Normalized performance
                          </p>
                        </div>
                      </template>
                    </v-checkbox> -->
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <v-card class="card-shadow border-radius-xl" v-show="user.claims.user_role != 'customer_view'">
            <div class="pb-0 px-4 pt-4">
              <div class="d-flex align-items-center">
                <h6 class="text-h6 text-typo font-weight-bold">
                  Upload Monthly kWh:
                </h6>
              </div>
            </div>
            <div>
              <v-row class="mt-n2">
                <v-col cols="4" class="ml-4">
                  <v-select
                    :items="months"
                    label="Select Month"
                    v-model="selectedUploadKwhMonth"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                    :items="monthlyUploadYears"
                    label="Select Year"
                    v-model="selectedUploadKwhYear"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Kwh"
                    type="number"
                    v-model="selectedUploadKwhAmount"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="mt-n6">
                  <v-btn
                    :ripple="false"
                    :elevation="0"
                    class="
                      font-weight-bold
                      text-xs
                      btn-default
                      bg-gradient-default
                    "
                    id="metric_update_btn"
                    @click="uploadMonthlyMetrics()"
                    >Upload</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import { getCurrentInstance } from 'vue';
import RoundSlider from "vue-round-slider";
import Service from "@/services/Service.js";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import ComparativeEnergy from "./ComparativeEnergy.vue";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

export default {
  name: "site-metrics",
  components: {
    ComparativeEnergy,
    RoundSlider,
  },
  props: ["site_id", "nativeLink", "system", "plans"],
  data() {
    return {
      monthDisplay: true,
      yearDisplay: false,
      tab: null,
      NewTab: null,
      chartConsumption: "chart-consumption",
      chartWeather: "chart-weather",
      switche: false,
      switch1: true,
      switch2: true,
      switch3: false,
      switch4: false,
      switch5: true,
      sliderValue: "",
      sites: [],
      kwhSelect: true,
      kwhKwpSelect: false,
      performanceSelect: false,
      estimateSelect: true,
      projectedSelect: false,
      selectedMonthlyYear: null,
      selectedYearlyYear: null,
      selectedMonth: null,
      selectedUploadKwhYear: null,
      selectedUploadKwhMonth: null,
      selectedUploadKwhAmount: null,
      selectedTab: "month",
      siteName: null,
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      monthlyYears: [],
      monthlyUploadYears: [],
      yearlyYears: [2021, 2020],
      allTimeOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              hideOverflowingLabels: true,
              offsetY: 30,
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "8px",
            colors: ["#fff"],
          },
        },
        chart: {
          id: "all-time-data",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      options: {
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              hideOverflowingLabels: true,
              offsetY: 30,
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "8px",
            colors: ["#fff"],
          },
        },
        chart: {
          id: "site-data",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      yearlyOptions: {
        chart: {
          id: "yearly-data",
          type: "line",
        },
        stroke: {
          curve: "smooth",
          width: [0, 4],
        },
        markers: {
          size: 0,
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      series: [
        {
          name: "kWh",
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        },
        {
          name: "normalized performance",
          data: [20, 30, 35, 40, 39, 50, 60, 81],
        },
        {
          name: "Performance (kWh/kWP)",
          data: [10, 20, 25, 30, 29, 40, 50, 71],
        },
      ],
      headers: [
        {
          text: "Site Name",
          value: "name",
          width: "80px",
        },
        {
          text: "Solar Company",
          value: "customerName",
          width: "100px",
        },
        {
          text: "Account",
          value: "customerAccountName",
          width: "100px",
        },
        {
          text: "",
          value: "monitoringSystem",
          width: 0,
          sortable: false,
        },
        {
          text: "Past 30 Days' System Health",
          value: "metrics.site.past30Days.systemHealth",
          width: 200,
        },
        {
          text: "Day's System Health",
          value: "metrics.site.day.systemHealth",
          width: 200,
        },
        {
          text: "Past 30 Days' Performance",
          value: "metrics.site.past30Days.performance",
        },
        {
          text: "Day's Performance",
          value: "metrics.site.day.performance",
        },
        {
          text: "Zip Code",
          value: "address.postalCode",
        },
        {
          text: "Possible Solutions",
          value: "possibleSolutions",
        },
        {
          text: "",
          value: "blueprintIconPlaceholder",
        },
        {
          text: "",
          value: "nativePlatformLinkIconPlaceholder",
        },
        {
          text: "",
          value: "emailCustomerIconPlaceholder",
        },
        {
          text: "",
          value: "addNoteIconPlaceholder",
        },
        {
          text: "",
          value: "addCaseIconPlaceholder",
        },
      ],
      rules: [(value) => !!value || "Required."],
    };
  },
  methods: {
    initialize(monthStr, year) {
      this.selectedMonthlyYear = year;
      this.selectedMonth = monthStr;
      this.selectedUploadKwhYear = year;
      this.selectedUploadKwhMonth = monthStr;
      console.log(
        `initialize...site_id: ${this.site_id} for ${monthStr}, ${year}`
      );
      this.loadMetricYears();
      this.loadMonthlyMetrics();
    },
    kwhSelectSwitch(newValue, oldValue) {
      this.kwhSelect = newValue;
      if (this.selectedTab == "month") {
        this.loadMonthlyMetrics();
      } else if (this.selectedTab == "year") {
        this.loadYearlyMetrics();
      } else {
        this.loadAllTimeMetrics();
      }
    },
    // kwhKwpSelectSwitch(newValue, oldValue) {
    //   this.kwhKwpSelect = newValue;
    //   if (this.selectedTab == "month") {
    //     this.loadMonthlyMetrics();
    //   } else if (this.selectedTab == "year") {
    //     this.loadYearlyMetrics();
    //   } else {
    //     this.loadAllTimeMetrics();
    //   }
    // },
    projectedSelectSwitch(newValue, oldValue) {
      this.projectedSelect = newValue;
      if (this.selectedTab == "month") {
        this.loadMonthlyMetrics();
      } else if (this.selectedTab == "year") {
        this.loadYearlyMetrics();
      } else {
        this.loadAllTimeMetrics();
      }
    },
    estimateSelectSwitch(newValue, oldValue) {
      this.estimateSelect = newValue;
      if (this.selectedTab == "month") {
        this.loadMonthlyMetrics();
      } else if (this.selectedTab == "year") {
        this.loadYearlyMetrics();
      } else {
        this.loadAllTimeMetrics();
      }
    },
    // performanceSelectSwitch(newValue, oldValue) {
    //   this.performanceSelect = newValue;
    //   if (this.selectedTab == "month") {
    //     this.loadMonthlyMetrics();
    //   } else if (this.selectedTab == "year") {
    //     this.loadYearlyMetrics();
    //   } else {
    //     this.loadAllTimeMetrics();
    //   }
    // },
    loadMetricYears() {
      Service.getSiteMetricYears(this.site_id)
        .then((response) => {
          // if we have data for years, then use them... if not default to the past 4 years
          if (response.data.years.length > 0) {
            this.monthlyYears = response.data.years;
            this.monthlyUploadYears = response.data.years;
            this.yearlyYears = response.data.years;
          } else {
            this.monthlyYears = this.$data.monthlyYears;
            this.monthlyUploadYears = this.getFourMostRecentYears();
            this.yearlyYears = this.$data.yearlyYears;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFourMostRecentYears() {
      let years = [];
      let currentYear = new Date().getFullYear(); // current
      for(var i = 0; i < 4; i++)
      {
        years.push(currentYear);
        currentYear--;
        // years.push(year)
      }
      return years;
    },
    loadAllTimeMetrics() {
      NProgress.start();
      var that = this;
      Service.getSiteCompEnergyYear(this.site_id)
        .then((response) => {
          console.log(response.data);
          let newXAxis = response.data.alltime_data.map((d) => {
            return d.year;
          });
          console.log(newXAxis);
          this.allTimeOptions = {
            xaxis: {
              categories: newXAxis,
            },
          };
          // let s = response.data.alltime_data.map((d) => {
          //   return {
          //     name: `${d.year.toString()}`,
          //     type: 'column',
          //     data: [d.kwh]
          //   }
          // })
          // this.series = [...s];
          let kwhData = response.data.alltime_data.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return d.kwh;
          });
          let kwhEstimateData = response.data.alltime_data.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return d.kwh_estimate;
          });
          let projectedData = response.data.alltime_data.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return d.projected;
          });
          // let perfData = response.data.alltime_data.map((d) => {
          //   // return Math.round(d.kwh * 100) / 100;
          //   return d.performance;
          // });
          // let kwhKwpData = response.data.alltime_data.map((d) => {
          //   // return Math.round(d.kwh * 100) / 100;
          //   return d.kwh_kwp;
          // });
          this.series = this.renderNewSeries(
            kwhData,
            // perfData,
            // kwhKwpData,
            kwhEstimateData,
            projectedData
          );
          NProgress.done();
        })
        .catch((error) => {
          console.error(error);
          NProgress.done();
        });
    },
    loadMonthlyMetrics() {
      let year = this.selectedMonthlyYear;
      let month = this.getMonthFromString(this.selectedMonth);
      NProgress.start();
      Service.getSiteMonthlyStats(this.site_id, month, year)
        .then((response) => {
          // this.sites = response.data.sites;
          console.log(response.data);
          let newXAxis = response.data.map((d) => {
            var d = new Date(`${d.date} 00:00:00`);
            return d.getDate();
          });
          console.log(newXAxis);
          this.options = {
            chart: {
              id: "monthly-stats",
            },
            xaxis: {
              categories: newXAxis,
            },
          };
          let kwhData = response.data.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return parseFloat(d.kwh).toFixed(1);
          });
          let projectedData = response.data.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return parseFloat(d.daily_average_projected).toFixed(1);
          });
          let kwhEstimateData = response.data.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return parseFloat(d.kwh_estimate).toFixed(1);
          });
          // let perfData = response.data.map((d) => {
          //   // return Math.round(d.kwh * 100) / 100;
          //   return parseFloat(d.performance).toFixed(1);
          // });
          // let kwhKwpData = response.data.map((d) => {
          //   // return Math.round(d.kwh * 100) / 100;
          //   return parseFloat(d.kwh_kwp).toFixed(1);
          // });
          this.series = this.renderNewSeries(
            kwhData,
            kwhEstimateData,
            projectedData
            // perfData,
            // kwhKwpData,
            
          );
          NProgress.done();
        })
        .catch((error) => {
          //   NProgress.done();
          // Vue.$toast.error(`Error reloading data`, { position: "top-right" });
          console.error(error);
        });
    },
    uploadMonthlyMetrics() {
      let year = this.selectedUploadKwhYear;
      let month = this.getMonthFromString(this.selectedUploadKwhMonth);
      let month_kwh_amount = this.selectedUploadKwhAmount;

      if (month_kwh_amount) {
        Service.updateSiteMonthlyKwh(
          this.site_id,
          month,
          year,
          month_kwh_amount
        )
          .then((response) => {
            console.log("Monthly KWH successfully updated");
            console.log(response);
            this.loadMonthlyMetrics();
          })
          .catch((error) => {
            console.log("Monthly KWH not updated");
            console.error(error);
          });
      }
    },
    loadYearlyMetrics() {
      let year = this.selectedYearlyYear;
      NProgress.start();
      var that = this;
      Service.getSiteYearlyComboStats(this.site_id, year)
        .then(function (values) {
          console.log(values);
          let yearlyData = values[0].data;
          let s_comp = values[1].data;
          var siteCompEnergy = s_comp.filter(function (d) {
            return d.year != that.selectedYearlyYear;
          });
          let newXAxis = yearlyData.map((d) => {
            var d = new Date(`${d.date} 00:00:00`);
            return that.getMonthStrFromInt(d.getMonth());
          });
          console.log(newXAxis);
          that.yearlyOptions = {
            xaxis: {
              categories: newXAxis,
            },
          };

          let kwhData = yearlyData.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return parseFloat(d.kwh).toFixed(1);
          });
          let kwhEstimateData = yearlyData.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return parseFloat(d.kwh_estimate).toFixed(1);
          });
          let projectedData = yearlyData.map((d) => {
            // return Math.round(d.kwh * 100) / 100;
            return parseFloat(d.projected).toFixed(1);
          });
          // let perfData = yearlyData.map((d) => {
          //   // return Math.round(d.kwh * 100) / 100;
          //   return parseFloat(d.performance).toFixed(1);
          // });
          // let kwhKwpData = yearlyData.map((d) => {
          //   // return Math.round(d.kwh * 100) / 100;
          //   return parseFloat(d.kwh_kwp).toFixed(1);
          // });
          // this.series = this.renderSeries(kwhData, perfData, kwhKwpData)
          let s = that.renderNewSeries(
            kwhData,
            // perfData,
            // kwhKwpData,
            kwhEstimateData,
            projectedData

          );
          for (let i = 0; i < siteCompEnergy.length; i++) {
            let kwhData = siteCompEnergy[i].data.map((k) => {
              return k.kwh === null ? 0 : parseFloat(k.kwh).toFixed(1);
            });
            s.push({
              name: `${siteCompEnergy[i].year.toString()}`,
              type: "line",
              data: kwhData,
            });
          }
          that.series = s;
          NProgress.done();
        })
        .catch((error) => {
          console.error(error);
          NProgress.done();
        });
    },
    reloadMonthlyData(site_id) {
        Service.reloadMissingMonthlyData(site_id)
        .then((response) => {
          console.log(`reload job started for site ${site_id}`);
          Vue.$toast.success(`Reload started.`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          console.error("Error calling reload data:", error.response);
        });
    },
    renderNewSeries(kwhData, kwhEstimateData, projectedData) {
      // insert series as needed
      let d = [];
      if (this.kwhSelect) {
        d.push({
          name: "kWh",
          type: "column",
          data: kwhData,
        });
      }
      if (this.estimateSelect) {
        d.push({
          name: "kWh Estimate",
          type: "column",
          data: kwhEstimateData,
        });
      }
      if (this.projectedSelect) {
        d.push({
          name: "Projected kWh",
          type: "column",
          data: projectedData,
        });
      }
      // if (this.performanceSelect) {
      //   d.push({
      //     name: "Normalized performance",
      //     type: "column",
      //     data: perfData,
      //   });
      // }
      // if (this.kwhKwpSelect) {
      //   d.push({
      //     name: "Performance (kWh/kWP)",
      //     type: "column",
      //     data: kwhKwpData,
      //   });
      // }
      return [...d];
    },
    nextMonth() {
      let year = this.selectedMonthlyYear;
      let month = this.getMonthFromString(this.selectedMonth);
      var newDate = new Date(Date.parse(month + " 1, " + year));
      newDate.setMonth(newDate.getMonth() + 1);
      console.log(newDate);
      // set month and year, then load data
      this.selectedMonthlyYear = newDate.getFullYear();
      this.selectedMonth = this.getMonthStrFromInt(newDate.getMonth());
      this.loadMonthlyMetrics();
    },
    nextYear() {
      let year = this.selectedYearlyYear;

      var newDate = new Date(Date.parse("1," + " 1, " + year));
      newDate.setFullYear(newDate.getFullYear() + 1);
      console.log(newDate);
      // set month and year, then load data
      this.selectedYearlyYear = newDate.getFullYear();
      this.loadYearlyMetrics();
    },
    previousMonth() {
      let year = this.selectedMonthlyYear;
      let month = this.getMonthFromString(this.selectedMonth);
      var newDate = new Date(Date.parse(month + " 1, " + year));
      newDate.setMonth(newDate.getMonth() - 1);
      console.log(newDate);
      console.log(this.kwhSelect);
      // set month and year, then load data
      this.selectedMonthlyYear = newDate.getFullYear();
      this.selectedMonth = this.getMonthStrFromInt(newDate.getMonth());
      this.loadMonthlyMetrics();
    },
    previousYear() {
      let year = this.selectedYearlyYear;
      var newDate = new Date(Date.parse("1," + " 1, " + year));
      newDate.setFullYear(newDate.getFullYear() - 1);
      console.log(newDate);
      // set month and year, then load data
      this.selectedYearlyYear = newDate.getFullYear();
      this.loadYearlyMetrics();
    },
    selectMonthlyTab() {
      console.log("selected monthly tab...");
      this.selectedTab = "month";
      this.monthDisplay = true;
      this.yearDisplay = false;
      this.loadMonthlyMetrics();
    },
    selectYearlyTab() {
      console.log("selected yearly tab...");
      this.selectedTab = "year";
      this.monthDisplay = false;
      this.yearDisplay = true;
      var d = new Date();
      var y = d.getFullYear();
      // init year
      this.selectedYearlyYear = y;
      this.loadYearlyMetrics();
    },
    selectAllTimeTab() {
      this.selectedTab = "alltime";
      this.loadAllTimeMetrics();
      this.monthDisplay = false;
      this.yearDisplay = false;
    },
    getMonthFromString(mon) {
      return new Date(Date.parse(mon + " 1, 2012")).getMonth() + 1;
    },
    getMonthStrFromInt(m) {
      return this.months[m];
    },
    openWindow: function (link) {
      window.open(link, "_blank");
    },
  },
  computed: {
    user: function () {
      return this.$store.getters.currentUser;
    },
  },
  created() {
    //   init with current month/year
    var d = new Date();
    var m = d.getMonth();
    var y = d.getFullYear();
    this.initialize(this.getMonthStrFromInt(m), y);
  },
};
</script>
<style scoped>
#customize_view_btn {
  float: right;
}
#metric_update_btn {
  float: right;
  margin-right: 5%;
}
#left_arrow {
  min-width: 32px;
  width: 36px;
  height: 32px;
}
#right_arrow {
  min-width: 32px;
  width: 36px;
  height: 32px;
}
/* #metric_stuff {
    background-color: #f0f2f5;
} */
#power_title {
  margin: 0;
  display: inline-block;
}
#monitoring_link_btn {
  /* float: left; */
  margin-left: 2%;
}
#site_plans_btn {
  /* float: left; */
  margin-left: 1%;
}
#inverter_btn {
  margin-left: 1%;
}
</style>