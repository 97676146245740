<template>
  <div>
    <h6>Comparative Energy</h6>
    <v-tabs>
      <v-tab @change="selectMonthlyTab()">Month</v-tab>
      <v-tab @change="selectYearlyTab()">Year</v-tab>
      <v-tab-item>
        <v-row>
          <v-col style="padding: 0px" cols="12">
            <apexchart
              width="800"
              height="400"
              type="bar"
              :options="monthOptions"
              :series="series"
            ></apexchart>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col style="padding: 0px" cols="12">
            <!-- <apexchart
              width="800"
              height="400"
              type="bar"
              :options="yearOptions"
              :series="series"
            ></apexchart> -->
          </v-col>
        </v-row>          
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Service from "@/services/Service.js";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

export default {
  props: ["site_id"],
  data() {
    return {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      
      monthOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              hideOverflowingLabels: true,
              offsetY: 30,
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "8px",
            colors: ["#fff"],
          },
        },
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        },
      },
      yearOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              hideOverflowingLabels: true,
              offsetY: 30,
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "8px",
            colors: ["#fff"],
          },
        },
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },        
      series: [
        {
          name: "kWh",
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        },
        {
          name: "normalized performance",
          data: [20, 30, 35, 40, 39, 50, 60, 81],
        },
        {
          name: "Performance (kWh/kWP)",
          data: [10, 20, 25, 30, 29, 40, 50, 71],
        },
      ],
    };
  },
  methods: {
    initialize() {
      
      this.loadCompEnergyMonth();
    },
    loadCompEnergyMonth() {
        Service.getSiteCompEnergyMonth(this.site_id)
          .then((response) => {
            let series = []
            console.log(response.data);
            let s = response.data.map((d) => {
                var name = d.year.toString()
                let kwhData = d.data.map((k) => {
                    if (k.kwh === null) {
                        return 0
                    } else {
                        return parseFloat(k.kwh).toFixed(1)
                    }
                    
                });
                return {name: name, data: kwhData};
            });
            series = [...s];
            this.series = series;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {},
  created() {
    
    this.initialize();
  },
};
</script>

<style>
</style>