<template>
<div class="d-flex justify-start">
  <component :is="field.component"
             :required="field.required"
             :readonly="!thisFieldIsBeingEdited"
             :class="{'readonly-input': !thisFieldIsBeingEdited}"
             :style="inputStyleObj"
             :label="field.label"
             :type="field.type"
             :step="step"
             :items="itemsComputed"
             :success="success"
             :error="error"
             :messages="messages"
             v-model="vModel"
             @change="submitSelectChange()"
             @keydown.tab.prevent="submitChange('tab')"
             @keydown.enter="submitChange('enter')"
             @dblclick="toggleThisFieldBeingEdited">

    <v-icon slot="append"
            :style="submitIconStyleObj"
            @click="submitChange('click')">
      mdi-check-circle
    </v-icon>

    <v-icon slot="append"
            class="edit-icon"
            :class="editIconClassObj"
            :id="`${field.objectPath}-edit-icon`"
            @mouseover="mouseoverOrOutIcon('over')"
            @mouseout="mouseoverOrOutIcon('out')"
            @click="toggleThisFieldBeingEdited">
      {{ editIconName }}
    </v-icon>
    <!-- Copy icon added below -->
    <v-icon slot="append" @click="copyToClipboard(vModel)">
      {{ copied ? 'mdi-check' : 'mdi-content-copy' }}
    </v-icon>
  </component>
</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { VSelect, VTextField } from 'vuetify/lib';
import { api, editing } from './mixins';

export default {
  name: 'SiteDetailInput',
  props: {
    field: {
      type: Object,
      required: true,
    },
    inverterId: {
      type: String,
      required: false,
    },
  },
  components: {
    VSelect,
    VTextField,
  },
  mixins: [
    api,
    editing,
  ],
  data() {
    return {
      thisEditIconIsBeingHovered: false,
      success: false,
      error: false,
      messages: '',
      copied: false, // Indicates whether content has been copied
    };
  },
  computed: {
    itemsComputed() {
      let ret;

      if (this.field.items) {
        ret = [...this.field.items];

        // do not display mediumLow or mediumHigh for summer and winter
        if (this.field.itemsComputedType === 'shades') {
          if (this.field.objectPath === 'shade.summer' || this.field.objectPath === 'shade.winter') {
            ret = ret.filter(shade => shade.value !== 'mediumLow' &&
              shade.value !== 'mediumHigh');
          }

          // disable options for winter based on fall/spring
          if (this.field.objectPath === 'shade.winter') {
            const fallSpringShade = this.$store.state.sites.currentSite.shade.fallSpring;
            const allowedWinterShadesByFallSpring = {
              low: ['low'],
              mediumLow: ['medium'],
              medium: ['medium', 'high'],
              mediumHigh: ['high'],
              high: ['high'],
            };

            const allowedShades = allowedWinterShadesByFallSpring[fallSpringShade];

            if (allowedShades) {
              ret = ret.map(shade => {
                const newShade = Object.assign({}, shade);

                if (!allowedShades.includes(shade.value)) {
                  newShade.disabled = true;
                }

                return newShade;
              });
            }
          }
        }
        // add empty option to reset value
        const emptyValue = {
          value: '',
          text: '',
        };

        ret.splice(0, 0, emptyValue);
      }

      return ret;
    },
    editIconName() {
      let name = 'mdi-pencil';

      if (this.thisFieldIsBeingEdited) {
        name = 'mdi-pencil-off';
      }

      return name;
    },
    inputStyleObj() {
      return {
        'z-index': this.thisFieldIsBeingEdited ? 9999 : null,
      };
    },
    editIconClassObj() {
      return {
        highlighted: this.thisEditIconIsBeingHovered || this.thisFieldIsBeingEdited,
      };
    },
    submitIconStyleObj() {
      return {
        display: this.thisFieldIsBeingEdited && this.field.component === 'v-text-field' ? 'inline-flex' : 'none',
      };
    },
    step() {
      let ret = null;

      if (this.field.type === 'number') {
        const steps = {
          0: '1',
          1: '0.1',
          2: '0.01',
          3: '0.001'
        };

        ret = steps[this.field.decimalPlaces];
      }

      return ret;
    },
    ...mapState({
      currentSite: (state) => state.sites.currentSite,
      currentSiteInverters: (state) => state.sites.currentSiteInverters,
      inverterIdBeingEdited: (state) => state.sites.inverterIdBeingEdited,
      objectPathBeingEdited: (state) => state.sites.objectPathBeingEdited,
      valueBeingEdited: (state) => state.sites.valueBeingEdited,
    }),
  },
  watch: {
    objectPathBeingEdited(val) {
      if (val === 'shade.fallSpring' && !!this.currentSite.shade.winter) {
        this.submitChange(null, true, 'shade.winter', null, true);
      }
    }
  },
  methods: {
    copyToClipboard(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          this.copied = true;
          setTimeout(() => this.copied = false, 2000); // Reset the copied flag after 2 seconds
        }).catch(err => {
          console.error('Failed to copy content: ', err);
        });
      } else {
        // Fallback method for older browsers
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }
    },
    mouseoverOrOutIcon(overOrOut) {
      overOrOut === 'over' ?
        this.thisEditIconIsBeingHovered = true :
        this.thisEditIconIsBeingHovered = false;
    },
    editNextInputOnTab(eventType) {
      if (eventType === 'tab') {
        const allInputs = Array.from(document.querySelectorAll('input'));

        const thisInput = this.$el.querySelector('input');

        const thisInputIndex = allInputs.findIndex(input => input === thisInput);

        const nextInput = allInputs[thisInputIndex + 1];

        const nextInputAppended = nextInput.parentElement.parentElement.querySelector('.v-input__append-inner');

        const nextInputIcons = Array.prototype.slice.call(nextInputAppended.querySelectorAll('i'));

        const nextInputEditIcon = Array.prototype.find.call(nextInputIcons, i => Array.prototype.includes.call(i.classList, 'mdi-pencil'));

        this.$nextTick(() => {
          nextInputEditIcon.click();
        });
      }
    },
    ...mapMutations([
      'setCurrentSite',
      'setCurrentSiteInverters',
      'setInverterIdBeingEdited',
      'setObjectPathBeingEdited',
      'setValueBeingEdited',
    ]),
  },
};
</script>
<style scoped>
  .readonly-input input {
    user-select: text;
  }
</style>
