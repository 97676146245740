<template>
<v-card style="margin-bottom: 20px;">
  <v-card-text>
    <v-row justify="start">
      <v-col sm="6"
             v-for="field in ['name', 'inverterMonitoringId']"
             :key="`${inverterId}-${fields[field].objectPath}`">
        <site-detail-input :field="fields[field]"
                           :inverterId="inverterId" />
      </v-col>
    </v-row>

    <v-row justify="start">
      <v-col sm="6"
             v-for="field in ['dCSize', 'aCSize']"
             :key="`${inverterId}-${fields[field].objectPath}`">
        <site-detail-input :field="fields[field]"
                           :inverterId="inverterId" />
      </v-col>
    </v-row>

    <v-row justify="start">
      <v-col sm="6"
             v-for="field in ['azimuth', 'tilt']"
             :key="`${inverterId}-${fields[field].objectPath}`">
        <site-detail-input :field="fields[field]"
                           :inverterId="inverterId" />
      </v-col>
    </v-row>
    <v-row justify="start">
      <v-col sm="6">Paused 
        <v-checkbox
          v-model="isPaused"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-card-text>
  <v-card-actions>
    <v-btn text
           color="error"
           @click="toggleShowConfirmModal(true)">
      Remove
    </v-btn>
  </v-card-actions>

  <v-row justify="center">
    <v-dialog v-model="showConfirmModal"
              persistent
              max-width="290">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>Do you really want to remove this inverter?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black"
                 outlined
                 @click="toggleShowConfirmModal(false)">
            Cancel
          </v-btn>
          <v-btn color="error"
                 @click="deleteInverter()">
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

</v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Service from '@/services/Service.js';
import { createInverterFields } from '../../modules';
import SiteDetailInput from '../SiteDetailInput';
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
// import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

export default {
  name: 'SiteDetailInverter',
  props: {
    inverterId: {
      type: String,
      required: true,
    },
  },
  components: {
    SiteDetailInput,
  },
  data() {
    return {
      fields: null,
      showConfirmModal: false,
    };
  },
  computed: {
    ...mapState({
      currentSite: (state) => state.sites.currentSite,
      currentSiteInverters: (state) => state.sites.currentSiteInverters,
    }),
    isPaused: {
      get()
      {
        let inv = this.currentSiteInverters.find(inverter => inverter.id === this.inverterId);
        return inv.isPaused;
      },
      set(newValue) {
        let params = {
          isPaused: newValue,
        };
        console.log(newValue);
        Service.updateInverter(this.inverterId, params)
          .then((response) => {
            console.log("flag isPaused updated");
            Vue.$toast.success(`Settings saved.`, {
              position: "top-right",
              duration: 4000,
            });
          })
          .catch((error) => {
            console.error("Error updating site data:", error.response);
          });
      }
    },
  },
  beforeMount() {
    this.fields = createInverterFields(this.currentSiteInverters.find(inverter => inverter.id === this.inverterId));
  },
  methods: {
    toggleShowConfirmModal(show) {
      this.showConfirmModal = show;
    },
    deleteInverter() {
      this.showConfirmModal = false;
      this.$emit('delete-inverter', this.inverterId)
    },
    ...mapMutations([
      'setCurrentSiteInverters',
      'setInverterJustDeleted',
    ]),
  },
};
</script>
