var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "", id: "metric_stuff" } },
        [
          _c(
            "div",
            { staticClass: "d-flex-row" },
            [
              _c("h3", { staticClass: "mt-6", attrs: { id: "power_title" } }, [
                _vm._v("Power and Energy"),
              ]),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default btn-outline-default",
                  attrs: {
                    ripple: false,
                    elevation: 0,
                    color: "transparent",
                    id: "monitoring_link_btn",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openWindow(_vm.nativeLink)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/" + this.system + ".png"),
                    },
                  }),
                ]
              ),
              _vm.plans
                ? _c(
                    "v-btn",
                    {
                      staticClass:
                        "font-weight-bold text-xs btn-default bg-gradient-default",
                      attrs: {
                        ripple: false,
                        elevation: 0,
                        id: "site_plans_btn",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openWindow(_vm.plans)
                        },
                      },
                    },
                    [_vm._v(" Site Plans ")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, id: "inverter_btn" },
                  on: {
                    click: function ($event) {
                      return _vm.openWindow(
                        "/sites/" + _vm.site_id + "/inverters_list"
                      )
                    },
                  },
                },
                [_vm._v(" Inverters ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, id: "inverters_btn" },
                  on: {
                    click: function ($event) {
                      return _vm.reloadMonthlyData(_vm.site_id)
                    },
                  },
                },
                [_vm._v(" Reload Monthly Data ")]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-1" },
            [
              _c(
                "v-col",
                { attrs: { lg: "7" } },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { "background-color": "transparent", grow: "" },
                      model: {
                        value: _vm.NewTab,
                        callback: function ($$v) {
                          _vm.NewTab = $$v
                        },
                        expression: "NewTab",
                      },
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          attrs: { href: "#metrics_month" },
                          on: {
                            change: function ($event) {
                              return _vm.selectMonthlyTab()
                            },
                          },
                        },
                        [_vm._v(" Month ")]
                      ),
                      _c(
                        "v-tab",
                        {
                          attrs: { href: "#metrics_year" },
                          on: {
                            change: function ($event) {
                              return _vm.selectYearlyTab()
                            },
                          },
                        },
                        [_vm._v(" Year ")]
                      ),
                      _c(
                        "v-tab",
                        {
                          attrs: { href: "#metrics_all_time" },
                          on: {
                            change: function ($event) {
                              return _vm.selectAllTimeTab()
                            },
                          },
                        },
                        [_vm._v(" All Time ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.NewTab,
                        callback: function ($$v) {
                          _vm.NewTab = $$v
                        },
                        expression: "NewTab",
                      },
                    },
                    [
                      _c(
                        "v-tab-item",
                        { attrs: { value: "metrics_month" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "card-shadow border-radius-xl h-100",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "px-4 py-4" },
                                [
                                  _c("apexchart", {
                                    attrs: {
                                      height: "400",
                                      type: "bar",
                                      options: _vm.options,
                                      series: _vm.series,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "metrics_year" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "card-shadow border-radius-xl h-100",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "px-4 py-4" },
                                [
                                  _c("apexchart", {
                                    attrs: {
                                      height: "400",
                                      type: "line",
                                      options: _vm.yearlyOptions,
                                      series: _vm.series,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "metrics_all_time" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "card-shadow border-radius-xl h-100",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "px-4 py-4" },
                                [
                                  _c("apexchart", {
                                    attrs: {
                                      height: "400",
                                      type: "bar",
                                      options: _vm.allTimeOptions,
                                      series: _vm.series,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "8", lg: "5" } },
                [
                  _vm.monthDisplay
                    ? _c(
                        "v-card",
                        {
                          staticClass:
                            "card-shadow border-radius-xl mt-12 mb-8",
                        },
                        [
                          _c("div", { staticClass: "pb-0 px-4 pt-4" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "text-h6 text-typo mb-4 font-weight-bold",
                                  },
                                  [_vm._v(" Customize View ")]
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "ml-6 mt-4" },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.months,
                                            label: "Select Month",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.selectedMonth,
                                            callback: function ($$v) {
                                              _vm.selectedMonth = $$v
                                            },
                                            expression: "selectedMonth",
                                          },
                                        }),
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.monthlyYears,
                                            label: "Select Year",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.selectedMonthlyYear,
                                            callback: function ($$v) {
                                              _vm.selectedMonthlyYear = $$v
                                            },
                                            expression: "selectedMonthlyYear",
                                          },
                                        }),
                                        _c(
                                          "v-btn",
                                          {
                                            staticStyle: {
                                              "font-size": "10px",
                                            },
                                            attrs: {
                                              text: "",
                                              elevation: "0",
                                              id: "left_arrow",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.previousMonth()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "material-icons",
                                                attrs: { left: "" },
                                              },
                                              [_vm._v("chevron_left")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticStyle: {
                                              "font-size": "10px",
                                            },
                                            attrs: {
                                              text: "",
                                              elevation: "0",
                                              id: "right_arrow",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.nextMonth()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "material-icons",
                                                attrs: { right: "" },
                                              },
                                              [_vm._v("chevron_right")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "\n                      font-weight-bold\n                      text-xs\n                      btn-default\n                      bg-gradient-default\n                    ",
                                            attrs: {
                                              ripple: false,
                                              elevation: 0,
                                              id: "customize_view_btn",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.loadMonthlyMetrics()
                                              },
                                            },
                                          },
                                          [_vm._v(" Apply")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "\n                    bg-gradient-primary\n                    shadow-primary\n                    border-radius-md\n                    mt-n12\n                    ml-2\n                    mr-6\n                    mb-4\n                  ",
                                      },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "ml-3 pt-3",
                                          attrs: { color: "white" },
                                          on: { change: _vm.kwhSelectSwitch },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _c("div", [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-white text-sm mb-0 font-weight-bolder",
                                                        },
                                                        [_vm._v(" kWh ")]
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            1460329704
                                          ),
                                          model: {
                                            value: _vm.kwhSelect,
                                            callback: function ($$v) {
                                              _vm.kwhSelect = $$v
                                            },
                                            expression: "kwhSelect",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          staticClass: "ml-3",
                                          attrs: { color: "white" },
                                          on: {
                                            change: _vm.estimateSelectSwitch,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _c("div", [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "\n                            text-white text-sm\n                            mb-0\n                            text-capitalize\n                            font-weight-bolder\n                          ",
                                                        },
                                                        [_vm._v(" Estimate ")]
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            359606204
                                          ),
                                          model: {
                                            value: _vm.estimateSelect,
                                            callback: function ($$v) {
                                              _vm.estimateSelect = $$v
                                            },
                                            expression: "estimateSelect",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          staticClass: "ml-3",
                                          attrs: { color: "white" },
                                          on: {
                                            change: _vm.projectedSelectSwitch,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _c("div", [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "\n                            text-white text-sm\n                            mb-0\n                            text-capitalize\n                            font-weight-bolder\n                          ",
                                                        },
                                                        [_vm._v(" Projected ")]
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            1588460158
                                          ),
                                          model: {
                                            value: _vm.projectedSelect,
                                            callback: function ($$v) {
                                              _vm.projectedSelect = $$v
                                            },
                                            expression: "projectedSelect",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm.yearDisplay
                    ? _c(
                        "v-card",
                        {
                          staticClass:
                            "card-shadow border-radius-xl mt-12 mb-8",
                        },
                        [
                          _c("div", { staticClass: "pb-0 px-4 pt-4" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "text-h6 text-typo mb-4 font-weight-bold",
                                  },
                                  [_vm._v(" Customize View ")]
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "ml-6 mt-4" },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            disabled: true,
                                            items: _vm.months,
                                            label: "Select Month",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.selectedMonth,
                                            callback: function ($$v) {
                                              _vm.selectedMonth = $$v
                                            },
                                            expression: "selectedMonth",
                                          },
                                        }),
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.yearlyYears,
                                            label: "Select Year",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.selectedYearlyYear,
                                            callback: function ($$v) {
                                              _vm.selectedYearlyYear = $$v
                                            },
                                            expression: "selectedYearlyYear",
                                          },
                                        }),
                                        _c(
                                          "v-btn",
                                          {
                                            staticStyle: {
                                              "font-size": "10px",
                                            },
                                            attrs: {
                                              text: "",
                                              elevation: "0",
                                              id: "left_arrow",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.previousYear()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "material-icons",
                                                attrs: { left: "" },
                                              },
                                              [_vm._v("chevron_left")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticStyle: {
                                              "font-size": "10px",
                                            },
                                            attrs: {
                                              text: "",
                                              elevation: "0",
                                              id: "right_arrow",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.nextYear()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "material-icons",
                                                attrs: { right: "" },
                                              },
                                              [_vm._v("chevron_right")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "\n                      font-weight-bold\n                      text-xs\n                      btn-default\n                      bg-gradient-default\n                    ",
                                            attrs: {
                                              ripple: false,
                                              elevation: 0,
                                              id: "customize_view_btn",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.loadYearlyMetrics()
                                              },
                                            },
                                          },
                                          [_vm._v(" Apply")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "\n                    bg-gradient-primary\n                    shadow-primary\n                    border-radius-md\n                    mt-n12\n                    ml-2\n                    mr-6\n                    mb-4\n                  ",
                                      },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "ml-3 pt-3",
                                          on: { change: _vm.kwhSelectSwitch },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("div", [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-white text-sm mb-0 font-weight-bolder",
                                                      },
                                                      [_vm._v(" kWh ")]
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value: _vm.kwhSelect,
                                            callback: function ($$v) {
                                              _vm.kwhSelect = $$v
                                            },
                                            expression: "kwhSelect",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          staticClass: "ml-3",
                                          on: {
                                            change: _vm.estimateSelectSwitch,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("div", [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "\n                            text-white text-sm\n                            mb-0\n                            text-capitalize\n                            font-weight-bolder\n                          ",
                                                      },
                                                      [_vm._v(" Estimate ")]
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value: _vm.estimateSelect,
                                            callback: function ($$v) {
                                              _vm.estimateSelect = $$v
                                            },
                                            expression: "estimateSelect",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          staticClass: "ml-3",
                                          on: {
                                            change: _vm.projectedSelectSwitch,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("div", [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "\n                            text-white text-sm\n                            mb-0\n                            text-capitalize\n                            font-weight-bolder\n                          ",
                                                      },
                                                      [_vm._v(" Projected ")]
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value: _vm.projectedSelect,
                                            callback: function ($$v) {
                                              _vm.projectedSelect = $$v
                                            },
                                            expression: "projectedSelect",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _c(
                        "v-card",
                        {
                          staticClass:
                            "card-shadow border-radius-xl mt-12 mb-8",
                        },
                        [
                          _c("div", { staticClass: "pb-0 px-4 pt-4" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "text-h6 text-typo mb-4 font-weight-bold",
                                  },
                                  [_vm._v(" Customize View ")]
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "ml-6 mt-4" },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            disabled: true,
                                            items: _vm.months,
                                            label: "Select Month",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.selectedMonth,
                                            callback: function ($$v) {
                                              _vm.selectedMonth = $$v
                                            },
                                            expression: "selectedMonth",
                                          },
                                        }),
                                        _c("v-select", {
                                          attrs: {
                                            disabled: true,
                                            items: _vm.monthlyYears,
                                            label: "Select Year",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.selectedMonthlyYear,
                                            callback: function ($$v) {
                                              _vm.selectedMonthlyYear = $$v
                                            },
                                            expression: "selectedMonthlyYear",
                                          },
                                        }),
                                        _c(
                                          "v-btn",
                                          {
                                            staticStyle: {
                                              "font-size": "10px",
                                            },
                                            attrs: {
                                              text: "",
                                              elevation: "0",
                                              id: "left_arrow",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "material-icons",
                                                attrs: { left: "" },
                                              },
                                              [_vm._v("chevron_left")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticStyle: {
                                              "font-size": "10px",
                                            },
                                            attrs: {
                                              text: "",
                                              elevation: "0",
                                              id: "right_arrow",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "material-icons",
                                                attrs: { right: "" },
                                              },
                                              [_vm._v("chevron_right")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "\n                      font-weight-bold\n                      text-xs\n                      btn-default\n                      bg-gradient-default\n                    ",
                                            attrs: {
                                              ripple: false,
                                              elevation: 0,
                                              id: "customize_view_btn",
                                            },
                                          },
                                          [_vm._v(" Apply")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "\n                    bg-gradient-primary\n                    shadow-primary\n                    border-radius-md\n                    mt-n12\n                    ml-2\n                    mr-6\n                    mb-4\n                  ",
                                      },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "ml-3 pt-3",
                                          on: { change: _vm.kwhSelectSwitch },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("div", [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-white text-sm mb-0 font-weight-bolder",
                                                      },
                                                      [_vm._v(" kWh ")]
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value: _vm.kwhSelect,
                                            callback: function ($$v) {
                                              _vm.kwhSelect = $$v
                                            },
                                            expression: "kwhSelect",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          staticClass: "ml-3",
                                          on: {
                                            change: _vm.estimateSelectSwitch,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("div", [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "\n                            text-white text-sm\n                            mb-0\n                            text-capitalize\n                            font-weight-bolder\n                          ",
                                                      },
                                                      [_vm._v(" Estimate ")]
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value: _vm.estimateSelect,
                                            callback: function ($$v) {
                                              _vm.estimateSelect = $$v
                                            },
                                            expression: "estimateSelect",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          staticClass: "ml-3",
                                          on: {
                                            change: _vm.projectedSelectSwitch,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("div", [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "\n                            text-white text-sm\n                            mb-0\n                            text-capitalize\n                            font-weight-bolder\n                          ",
                                                      },
                                                      [_vm._v(" Projected ")]
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value: _vm.projectedSelect,
                                            callback: function ($$v) {
                                              _vm.projectedSelect = $$v
                                            },
                                            expression: "projectedSelect",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                  _c(
                    "v-card",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.user.claims.user_role != "customer_view",
                          expression:
                            "user.claims.user_role != 'customer_view'",
                        },
                      ],
                      staticClass: "card-shadow border-radius-xl",
                    },
                    [
                      _c("div", { staticClass: "pb-0 px-4 pt-4" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c(
                              "h6",
                              {
                                staticClass:
                                  "text-h6 text-typo font-weight-bold",
                              },
                              [_vm._v(" Upload Monthly kWh: ")]
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            { staticClass: "mt-n2" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "ml-4", attrs: { cols: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.months,
                                      label: "Select Month",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.selectedUploadKwhMonth,
                                      callback: function ($$v) {
                                        _vm.selectedUploadKwhMonth = $$v
                                      },
                                      expression: "selectedUploadKwhMonth",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.monthlyUploadYears,
                                      label: "Select Year",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.selectedUploadKwhYear,
                                      callback: function ($$v) {
                                        _vm.selectedUploadKwhYear = $$v
                                      },
                                      expression: "selectedUploadKwhYear",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Kwh",
                                      type: "number",
                                      rules: _vm.rules,
                                    },
                                    model: {
                                      value: _vm.selectedUploadKwhAmount,
                                      callback: function ($$v) {
                                        _vm.selectedUploadKwhAmount = $$v
                                      },
                                      expression: "selectedUploadKwhAmount",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "mt-n6", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "\n                    font-weight-bold\n                    text-xs\n                    btn-default\n                    bg-gradient-default\n                  ",
                                      attrs: {
                                        ripple: false,
                                        elevation: 0,
                                        id: "metric_update_btn",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.uploadMonthlyMetrics()
                                        },
                                      },
                                    },
                                    [_vm._v("Upload")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }